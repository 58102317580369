<template>
	<div class="container container-sm">
		<div class="row">
			<div class="col-12 col-md-4 position-relative">
				<div class="left-column">
					<el-card class="box-card">
						<div class="header mb-3 h4 text-start">管理人脈</div>
						<div class="clearfix text-start cardheard mb-3">
							<span>狀態</span>
						</div>
						<div
							class="border-bottom"
							v-for="c in connectionStatus"
							:key="c"
							:class="{ active: currentconnectionStatus == c.title }"
						>
							<el-button
								class="d-block ms-0 py-3"
								link
								@click="connectionSelectChange(c)"
							>
								{{ c.title }}</el-button
							>
						</div>
					</el-card>
				</div>
			</div>

			<div class="col-12 col-md-8 mt-3">
				<el-card class="box-card">
					<div class="clearfix text-start cardheard h4 border-bottom pb-3">
						<span>{{ currentconnectionStatus }}</span>
					</div>
					<template v-if="userList.length != 0">
						<div v-for="u in userList" :key="u" class="userItem">
							<div style="width: 50px">
								<el-avatar :size="50" :src="u.avatar"></el-avatar>
							</div>
							<div class="text-start ms-3 userName">
								<p
									class="Name"
									:class="{ 'mb-1': u.introduction !== '' }"
									@click="goTribe(u.id)"
								>
									{{ u.name }}
								</p>
								<div class="one-line-ellipsis">
									<p v-html="u.introduction"></p>
								</div>
							</div>
							<div class="ms-auto">
								<el-button
									v-if="currentconnectionStatus == '被邀請'"
									@click="addFriend(u)"
									>同意</el-button
								>
								<el-button @click="click_event(u)">{{
									currentBtnText
								}}</el-button>
							</div>
						</div>
					</template>
					<template v-else>
						<p>目前沒有成員</p>
					</template>
				</el-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			connectionStatus: [
				{ title: "聯絡人", apiRoute: "contactPerson" },
				{ title: "邀請中", apiRoute: "Invite" },
				{ title: "被邀請", apiRoute: "Invited" },
				{ title: "追蹤中", apiRoute: "Follower" },
			],
			currentconnectionStatus: "聯絡人",
			userList: [],
		};
	},
	computed: {
		currentBtnText() {
			let vm = this;
			if (vm.currentconnectionStatus == "聯絡人") return "解除";
			if (vm.currentconnectionStatus == "邀請中") return "取消邀請";
			if (vm.currentconnectionStatus == "被邀請") return "取消";
			if (vm.currentconnectionStatus == "追蹤中") return "取消追蹤";
			return "";
		},
	},
	mounted() {
		let vm = this;
		vm.fetchAPI(
			"get",
			`${process.env.VUE_APP_URL_API}/Connection/contactPerson`
		).then((res) => {
			console.log(res);
			vm.userList = res.data.connectionUsers;
		});
	},
	methods: {
		connectionSelectChange(c) {
			let vm = this;
			vm.currentconnectionStatus = c.title;
			vm.userList = [];
			vm.fetchAPI(
				"get",
				`${process.env.VUE_APP_URL_API}/Connection/${c.apiRoute}`
			).then((res) => {
				console.log(res);
				vm.userList = res.data.connectionUsers;
			});
		},
		goTribe(id) {
			let vm = this;
			vm.$router.push({
				name: "ChargeMeTribe",
				params: {
					Id: id,
				},
			});
		},
		click_event(u) {
			let vm = this;
			if (vm.currentconnectionStatus == "邀請中") return vm.cancelRequest(u);
			if (vm.currentconnectionStatus == "被邀請") return vm.cancelRequest(u);
			if (vm.currentconnectionStatus == "追蹤中") return vm.unFollower(u);
			if (vm.currentconnectionStatus == "聯絡人") return vm.deleteFriend(u);
		},
		addFriend(u) {
			let vm = this;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Connection/AddFriend/${u.dataId}`
			).then((res) => {
				console.log(res);
				vm.userList = vm.userList.filter((x) => x != u);
				vm.notify("success", `己成為好友`);
			});
		},
		cancelRequest(u) {
			let vm = this;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Connection/UnFriendRequest/${u.dataId}`
			).then((res) => {
				console.log(res);
				vm.userList = vm.userList.filter((x) => x != u);
				vm.notify("success", `已取消`);
			});
		},
		unFollower(u) {
			let vm = this;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Connection/UnFollower`,
				{ FollowerId: u.id }
			).then((res) => {
				console.log(res);
				vm.userList = vm.userList.filter((x) => x != u);
				vm.notify("success", `已取消`);
			});
		},
		deleteFriend(u) {
			let vm = this;
			vm.fetchAPI(
				"delete",
				`${process.env.VUE_APP_URL_API}/Connection/RemoveFriend/${u.id}`
			).then((res) => {
				console.log(res);
				vm.userList = vm.userList.filter((x) => x != u);
				vm.notify("success", `已解除`);
			});
		},
	},
};
</script>

<style>
.fixed {
	padding: 20px 10px;
	width: 300px !important;
	background-color: #f0f2f5 !important;
	position: absolute;
	left: 0;
	top: 50px;
	bottom: 0;
	left: 0;
	margin: auto;
	overflow-y: auto; /* 垂直滾動 */
}

.active {
	background-color: rgba(0, 0, 0, 0.1);
}
.userItem {
	display: flex;
	padding: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	align-items: center;
}
.userName:hover > .Name {
	cursor: pointer;
	text-decoration: underline;
}
.one-line-ellipsis {
	text-align: start;
	margin-top: 0.5rem;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1; /* 显示的行数 */
}
</style>
